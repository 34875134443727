<template>
  <div>
    <div class="main-content mt-0">
      <div class="page-header align-items-start min-vh-100 fnd-login">
        <span class="mask bg-gradient-dark opacity-4"></span>
        <div class="container my-auto">
          <div class="row">
            <div class="col-12">
              <nav class="top-0 my-3 py-2 start-0 end-0">
                <div class="container ps-2 pe-0 text-center">
                  <h1 class="text-center font-weight-bold text-white">
                    SENA CTPI KEYS
                  </h1>
                </div>
              </nav>
            </div>
          </div>
          <div class="row" v-if="!statusLoading">
            <div class="col-lg-4 col-md-8 col-12 mx-auto">
              <div class="card z-index-0 fadeIn3 fadeInBottom">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1"
                  >
                    <h4
                      class="text-white font-weight-bolder text-center mt-2 mb-0"
                    >
                      Iniciar sesión
                    </h4>
                    <div class="row mt-3">
                      <div class="col-2 text-center ms-auto">
                        <a class="btn btn-link px-3" href="#">
                          <i class="fa fa-facebook text-white text-lg"></i>
                        </a>
                      </div>
                      <div class="col-2 text-center px-1">
                        <a class="btn btn-link px-3" href="#">
                          <i class="fa fa-github text-white text-lg"></i>
                        </a>
                      </div>
                      <div class="col-2 text-center me-auto">
                        <a class="btn btn-link px-3" href="#">
                          <i class="fa fa-google text-white text-lg"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="input-group input-group-dynamic my-3">
                    <label class="form-label"></label>
                    <input
                      v-model="UserName"
                      type="userName"
                      class="form-control"
                      placeholder="Usuario"
                    />
                  </div>
                  <div class="input-group input-group-dynamic mb-3">
                    <label class="form-label"> </label>
                    <input
                      id="txtPassword"
                      v-model="password"
                      type="password"
                      class="form-control"
                      placeholder="Contraseña"
                    />
                    <button
                      style="
                        border: none;
                        background-color: transparent;
                        outline: none;
                      "
                      id="show_password"
                      class="transparent text-center center"
                      @click="mostrarPassword()"
                    >
                      <fa icon="eye-slash" />
                    </button>
                  </div>
                  <br />
                  <div class="text-center">
                    <button
                      @click="iniciarSesion()"
                      type="button "
                      class="btn bg-gradient-primary w-100 my-4 mb-2"
                    >
                      INGRESAR
                    </button>
                  </div>
                  <p class="text-center responsive" style="font-size: 0.8rem">
                    Servicio Nacional de Aprendizaje
                    <span class="text-primary font-weight-bold">SENA</span
                    ><br />
                    Centro de Teleinformática y Producción Industrial<br />
                    Fábrica de Software
                  </p>
                  <div
                    class="col-lg-12 col-12 col-md-12 col-ms-12 col-sm-12 d-flex justify-content-end"
                  >
                    <div>
                      <p
                        style="color: red"
                        class="justify-content-end pointer"
                        @click="manuales()"
                      >
                        ¿Cómo se usa?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="statusLoading" class="tamanocargando">
            <AnimationLoading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */ import { defineComponent } from "vue";
import Axios from "@/Axios";
import router from "@/router";
import Swal from "sweetalert2";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";
export default defineComponent({
  data() {
    return {
      statusLoading: false,
      UserName: "",
      password: "",
      user: { user_name: "", password: "" },
    };
  },
  components: { AnimationLoading },
  methods: {
    manuales() {
      router.push({ name: "Manuales" });
    },
    iniciarSesion() {
      (this.statusLoading = true),
        Axios()
          .post("iniciar_sesion", {
            user_name: this.UserName,
            password: this.password,
          })
          .then((respuesta) => {
            if (respuesta.data.status == "success") {
              sessionStorage.setItem(
                "usuario",
                JSON.stringify(respuesta.data.results)
              );
              if (
                respuesta.data.results.profile.name_profile == "Administrador"
              ) {
                router.push({ name: "Menu" });
              } else if (
                respuesta.data.results.profile.name_profile == "Gestor"
              ) {
                router.push({ name: "MenuGestor" });
              } else if (
                respuesta.data.results.profile.name_profile == "Instructor"
              ) {
                router.push({
                  name: "MenuInstructor",
                  params: { id: this.UserName },
                });
              }
            } else if (this.UserName.length == 0 || this.password.length == 0) {
              this.statusLoading = false;
              Swal.fire({
                title: "Error al iniciar sesion",
                icon: "error",
                text: "Los campos no pueden estar vacios",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#008000",
              });
              this.statusLoading = false;
            } else {
              this.statusLoading = false;
              Swal.fire({
                title: "Error al iniciar sesion",
                icon: "error",
                text: respuesta.data.message,
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#008000",
              });
              this.statusLoading = false;
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
            this.statusLoading = false;
          });
    },
    mostrarPassword() {
      var cambio = document.getElementById("txtPassword");
      if (cambio.type == "password") {
        cambio.type = "text";
      } else {
        cambio.type = "password";
      }
    },
  },
});
</script>
<style scoped>
.fnd-login {
  background-image: url("../../src/assets/funciones.jpg");
  background-repeat: no-repeat;
  object-fit: cover;
}
.pointer:hover {
  cursor: pointer;
}
</style>

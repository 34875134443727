<template>
  <div class="home">
    <sign-in />
  </div>
</template>

<script >
import { defineComponent } from "vue";
import SignIn from "@/components/SignIn.vue"; // @ is an alias to /src

export default defineComponent({
  components: {
    SignIn,
  },

  name: "Home",
  data() {
    return {};
  },
});
</script>
